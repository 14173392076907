.pure-gallery {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    background: #fff;
}

.gallery-main {
    position: relative;
    width: 100%;
    padding-bottom: 75%;
    overflow: hidden;
    background: #fff;
    margin-bottom: 20px;
}

.gallery-main img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.gallery-main img.active {
    opacity: 1;
    z-index: 1;
}

.gallery-controls {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    pointer-events: none;
    z-index: 2;
}

.gallery-controls button {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: auto;
    transition: background-color 0.3s, opacity 0.3s;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-main:hover .gallery-controls button {
    opacity: 1;
}

.gallery-controls button:hover {
    background: rgba(var(--cnvs-themecolor-rgb), 0.8);
}

.gallery-controls button i {
    font-size: 24px;
    line-height: 1;
}

.gallery-thumbs {
    display: flex;
    gap: 10px;
    padding: 0 5px;
}

.gallery-thumbs button {
    flex: 1;
    max-width: 100px;
    aspect-ratio: 1;
    padding: 3px;
    border: 2px solid transparent;
    background: none;
    cursor: pointer;
    border-radius: 2px;
    overflow: hidden;
    transition: border-color 0.3s;
    opacity: 0.6;
}

.gallery-thumbs button:hover {
    opacity: 1;
}

.gallery-thumbs button.active {
    border-color: var(--cnvs-themecolor);
    opacity: 1;
}

.gallery-thumbs img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1px;
}

@media (max-width: 768px) {
    .gallery-main {
        padding-bottom: 75%;
    }
    
    .gallery-controls button {
        width: 40px;
        height: 40px;
    }
    
    .gallery-thumbs button {
        max-width: 70px;
    }
}

/* Add lightbox-like zoom effect on hover */
.gallery-main img {
    transform: scale(0.98);
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.gallery-main img.active {
    transform: scale(1);
}

/* Add these styles at the end of the file */
.pure-gallery.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.95);
    z-index: 9999;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.pure-gallery.fullscreen .gallery-main {
    flex: 1;
    margin: 0;
    padding: 0;
    height: calc(100vh - 100px);
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.pure-gallery.fullscreen .gallery-main img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 90vh;
    object-fit: contain;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.pure-gallery.fullscreen .gallery-main img.active {
    opacity: 1;
    z-index: 1;
}

.pure-gallery.fullscreen .gallery-thumbs {
    height: 80px;
    padding: 10px 0;
    justify-content: center;
}

.pure-gallery.fullscreen .gallery-thumbs button {
    flex: 0 0 auto;
    width: 80px;
    max-width: 80px;
}

.pure-gallery.fullscreen .gallery-controls {
    padding: 0 40px;
}

.pure-gallery.fullscreen .gallery-controls button {
    opacity: 0.8;
    width: 60px;
    height: 60px;
    background: rgba(0, 0, 0, 0.7);
}

.pure-gallery.fullscreen .fullscreen-button {
    top: 30px;
    right: 30px;
}

.fullscreen-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 3;
    opacity: 0;
    transition: opacity 0.3s, background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-main:hover .fullscreen-button {
    opacity: 1;
}

.fullscreen-button:hover {
    background: rgba(var(--cnvs-themecolor-rgb), 0.8);
}

.pure-gallery.fullscreen .fullscreen-button {
    opacity: 0.8;
}

/* Prevent body scroll when fullscreen */
body.gallery-fullscreen {
    overflow: hidden;
}

/* Update icon styles */
.gallery-controls button i {
    font-size: 24px;
    line-height: 1;
}

.fullscreen-button i {
    font-size: 20px;
}

/* Make sure icons are centered */
.gallery-controls button,
.fullscreen-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Update fullscreen controls to match theme */
.pure-gallery.fullscreen .gallery-controls button:hover,
.pure-gallery.fullscreen .fullscreen-button:hover {
    background: rgba(var(--cnvs-themecolor-rgb), 0.8);
} 