.pure-gallery {
  background: #fff;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.gallery-main {
  background: #fff;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
}

.gallery-main img {
  object-fit: contain;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-main img.active {
  opacity: 1;
  z-index: 1;
}

.gallery-controls {
  pointer-events: none;
  z-index: 2;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.gallery-controls button {
  color: #fff;
  cursor: pointer;
  pointer-events: auto;
  opacity: 0;
  background: #00000080;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  transition: background-color .3s, opacity .3s;
  display: flex;
}

.gallery-main:hover .gallery-controls button {
  opacity: 1;
}

.gallery-controls button:hover {
  background: rgba(var(--cnvs-themecolor-rgb), .8);
}

.gallery-thumbs {
  gap: 10px;
  padding: 0 5px;
  display: flex;
}

.gallery-thumbs button {
  aspect-ratio: 1;
  cursor: pointer;
  opacity: .6;
  background: none;
  border: 2px solid #0000;
  border-radius: 2px;
  flex: 1;
  max-width: 100px;
  padding: 3px;
  transition: border-color .3s;
  overflow: hidden;
}

.gallery-thumbs button:hover {
  opacity: 1;
}

.gallery-thumbs button.active {
  border-color: var(--cnvs-themecolor);
  opacity: 1;
}

.gallery-thumbs img {
  object-fit: cover;
  border-radius: 1px;
  width: 100%;
  height: 100%;
}

@media (width <= 768px) {
  .gallery-main {
    padding-bottom: 75%;
  }

  .gallery-controls button {
    width: 40px;
    height: 40px;
  }

  .gallery-thumbs button {
    max-width: 70px;
  }
}

.gallery-main img {
  transition: transform .5s, opacity .5s;
  transform: scale(.98);
}

.gallery-main img.active {
  transform: scale(1);
}

.pure-gallery.fullscreen {
  z-index: 9999;
  background: #000000f2;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.pure-gallery.fullscreen .gallery-main {
  background: none;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

.pure-gallery.fullscreen .gallery-main img {
  object-fit: contain;
  opacity: 0;
  width: auto;
  max-width: 90%;
  height: auto;
  max-height: 90vh;
  transition: opacity .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pure-gallery.fullscreen .gallery-main img.active {
  opacity: 1;
  z-index: 1;
}

.pure-gallery.fullscreen .gallery-thumbs {
  justify-content: center;
  height: 80px;
  padding: 10px 0;
}

.pure-gallery.fullscreen .gallery-thumbs button {
  flex: none;
  width: 80px;
  max-width: 80px;
}

.pure-gallery.fullscreen .gallery-controls {
  padding: 0 40px;
}

.pure-gallery.fullscreen .gallery-controls button {
  opacity: .8;
  background: #000000b3;
  width: 60px;
  height: 60px;
}

.pure-gallery.fullscreen .fullscreen-button {
  top: 30px;
  right: 30px;
}

.fullscreen-button {
  color: #fff;
  cursor: pointer;
  z-index: 3;
  opacity: 0;
  background: #00000080;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  transition: opacity .3s, background-color .3s;
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

.gallery-main:hover .fullscreen-button {
  opacity: 1;
}

.fullscreen-button:hover {
  background: rgba(var(--cnvs-themecolor-rgb), .8);
}

.pure-gallery.fullscreen .fullscreen-button {
  opacity: .8;
}

body.gallery-fullscreen {
  overflow: hidden;
}

.gallery-controls button i {
  font-size: 24px;
  line-height: 1;
}

.fullscreen-button i {
  font-size: 20px;
}

.gallery-controls button, .fullscreen-button {
  justify-content: center;
  align-items: center;
  display: flex;
}

.pure-gallery.fullscreen .gallery-controls button:hover, .pure-gallery.fullscreen .fullscreen-button:hover {
  background: rgba(var(--cnvs-themecolor-rgb), .8);
}
/*# sourceMappingURL=index.c23b0cce.css.map */
